import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Home.css';

import { 
  app_name,
  tag_line,
  ios_link,
  android_link,
  // twitter_url,
  // substack_url,
  // instagram_url,
  // tiktok_url,
} from '../utils/constants';

// const scorecard = require('../GR_Website_1.png');
// const history = require('../GR_Website_2.png');
// const log = require('../GR_Website_3.png');
// const publicEvents = require('../GR_Website_enter.png');
// const leaderboard = require('../GR_Website_5.png');
// const privateEvents = require('../GR_Website_create.png');
const logo = require('../logo.png');
const apple = require('../apple.png');
const android = require('../android.png');


export default function Home({isMobile}) {
  const navigate = useNavigate();

  const containerStyle = isMobile ? 'mobile-container' : 'container';
  const screenshotStyle = isMobile ? 'screenshot-mobile' : 'screenshot';


  const goTo = (path) => navigate(`/${path}/`);

  return (
    <div className={containerStyle}>
      <p className={isMobile ? "main-title-mobile" : "main-title"}>{app_name.toUpperCase()}</p>
      <p className={isMobile ? "subtitle-mobile" : "subtitle"}>{tag_line.toUpperCase()}</p>
      <img
        src={logo}
        alt="logo"
        className={isMobile ? "logo-mobile" : "logo"}
      />
      <div className={isMobile ? "download-row-mobile" : "download-row"}>
        <a href={ios_link} target="_blank" rel="noopener noreferrer">
          <img
            src={apple}
            alt="apple"
            className={isMobile ? "download-button-mobile" : "download-button"}
          />
        </a>
        <a href={android_link} target="_blank" rel="noopener noreferrer">
          <img
            src={android}
            alt="android"
            className={isMobile ? "download-button-mobile" : "download-button"}
          />
        </a>
      </div>
      {/* {
        isMobile 
        ?
        <div className='column'>
          <img
            src={publicEvents}
            alt="events"
            className={screenshotStyle}
          />  
          <img
            src={privateEvents}
            alt="stats"
            className={screenshotStyle}
          />
          <img
            src={leaderboard}
            alt="leaderboard"
            className={screenshotStyle}
          />
          <img
            src={log}
            alt="hole_detail"
            className={screenshotStyle}
          />
          <img
            src={scorecard}
            alt="scorecard"
            className={screenshotStyle}
          />
          <img
            src={history}
            alt="round_history"
            className={screenshotStyle}
          />
        </div>
        :
        <div>
          <div className='row'>
            <img
              src={publicEvents}
              alt="events"
              className={screenshotStyle}
            />  
            <img
              src={privateEvents}
              alt="stats"
              className={screenshotStyle}
            />
            <img
              src={leaderboard}
              alt="leaderboard"
              className={screenshotStyle}
            />
          </div>
          <div className="row">
            <img
              src={log}
              alt="hole_detail"
              className={screenshotStyle}
            />
            <img
              src={scorecard}
              alt="scorecard"
              className={screenshotStyle}
            />
            <img
              src={history}
              alt="round_history"
              className={screenshotStyle}
            />

          </div>
        </div>
      } */}

      <div className="footer-row">
        <div className="footer-column-left">
          <p className="footer-header-left">ABOUT</p>
          <p className="footer-text-left" onClick={() => goTo('contact')}>Contact</p>
          <p className="footer-text-left" onClick={() => goTo('terms')}>Terms</p>
          <p className="footer-text-left" onClick={() => goTo('privacy')}>Privacy</p>
        </div>
        {/* <div className="footer-column-right">
          <p className="footer-header-left">SOCIAL</p>
          <p className="footer-text-right" onClick={() => window.open(twitter_url, '_blank')}>X</p>
          <p className="footer-text-right" onClick={() => window.open(substack_url, '_blank')}>Substack</p>
          <p className="footer-text-right" onClick={() => window.open(instagram_url, '_blank')}>Instagram</p>
          <p className="footer-text-right" onClick={() => window.open(tiktok_url, '_blank')}>TikTok</p>
        </div> */}
      </div>
    </div>
  );
}