// GlobalState.js
import React, { useState, useEffect } from 'react';

const GlobalStateContext = React.createContext();

export const GlobalStateProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userIsSignedIn, setUserIsSignedIn] = useState(false);
  const [channelsList, setChannelsList] = useState([]);
  const [channelsMap, setChannelsMap] = useState({});
  const [currentChannelIndex, setCurrentChannelIndex] = useState(0);
  const [currentSelectionIndex, setCurrentSelectionIndex] = useState(0);
  const [badgeModalIsOpen, setBadgeModalIsOpen] = useState(false);
  const [saveProfileIsOpen, setSaveProfileIsOpen] = useState(false);
  const [gradientColors, setGradientColors] = useState(['#6BAAC9', '#FFFFFF']);


  const handleChannelsList = (channels) => {
    const channelsList = channels.map(c => c.channel_id);
    setChannelsList(channelsList);
    const channelMap = channels.reduce((acc, channel) => {
      acc[channel.channel_id] = channel;
      return acc;
    }, {});
    setChannelsMap(channelMap);
  };

  return (
    <GlobalStateContext.Provider 
      value={{ 
        user, 
        setUser,
        userIsSignedIn,
        setUserIsSignedIn,
        channelsList,
        channelsMap,
        setChannelsMap,
        handleChannelsList,
        currentChannelIndex,
        setCurrentChannelIndex,
        currentSelectionIndex,
        setCurrentSelectionIndex,
        badgeModalIsOpen,
        setBadgeModalIsOpen,
        saveProfileIsOpen,
        setSaveProfileIsOpen,
        gradientColors,
        setGradientColors,
        signInUser: (user) => {
          setUser(user);
          setUserIsSignedIn(true);
        },
        userSignOut: () => { 
          setUser(null);
          setUserIsSignedIn(false);
          localStorage.removeItem('token');
          localStorage.removeItem('id');
          localStorage.removeItem('user');
        },
      }}>
      {children}
    </GlobalStateContext.Provider>
  );
};

export const useGlobalState = () => {
  const context = React.useContext(GlobalStateContext);
  if (context === undefined) {
    throw new Error('useGlobalState must be used within a GlobalStateProvider');
  }
  return context;
};

export default GlobalStateContext;