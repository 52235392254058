import React from 'react';
import { renderEmoji } from '../utils/emojiUtils';
import '../CmsLanding.css';

// value1: Joi.string().max(50).required(),
// label1: Joi.string().max(50).required(),
// emoji1: Joi.string().max(30).required(),
// definition1: Joi.string().max(255).required(),
// value2: Joi.string().max(50).required(),
// label2: Joi.string().max(50).required(),
// emoji2: Joi.string().max(30).required(),
// definition2: Joi.string().max(255).required(),
// color: Joi.string().max(10).required()

const DichotomyList = ({ dichotomies, onItemClick, createButton }) => {
  return (
    <>
      {createButton}
      <table className="cms-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Value 1</th>
            <th>Label 1</th>
            <th>Emoji 1</th>
            <th>Definition 1</th>
            <th>Value 2</th>
            <th>Label 2</th>
            <th>Emoji 2</th>
            <th>Definition 2</th>
            <th>Color</th>
          </tr>
        </thead>
        <tbody>
          {dichotomies.map(dichotomy => (
            <tr key={dichotomy._id} onClick={() => onItemClick(dichotomy)}>
              <td>{dichotomy._id}</td>
              <td>{dichotomy.value1}</td>
              <td>{dichotomy.label1}</td>
              <td>{renderEmoji(dichotomy.emoji1)}</td>
              <td>{dichotomy.definition1}</td>
              <td>{dichotomy.value2}</td>
              <td>{dichotomy.label2}</td>
              <td>{renderEmoji(dichotomy.emoji2)}</td>
              <td>{dichotomy.definition2}</td>
              <td>
                <div className="color-preview" style={{backgroundColor: dichotomy.color}}></div>
                {dichotomy.color}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default DichotomyList;

