import React from 'react';
import '../CmsLanding.css';

const SelectionList = ({ selections, onItemClick, createButton }) => {
  return (
    <>
      {createButton}
      <table className="cms-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Active</th>
            <th>Channel ID</th>
            <th>Meme ID</th>
            <th>Spectrum ID</th>
          </tr>
        </thead>
        <tbody>
          {selections.map(selection => (
            <tr key={selection._id} onClick={() => onItemClick(selection)}>
              <td>{selection._id}</td>
              <td>{selection.active ? 'True' : 'False'}</td>
              <td>{selection.channel_id}</td>
              <td>{selection.meme_id}</td>
              <td>{selection.spectrum_id}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default SelectionList;
