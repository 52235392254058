import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGlobalState } from '../context/GlobalState';
import Profile from './Profile';
import './Header.css';
import logo from '../logo.png';

const Header = ({ isMobile }) => {
  const { user, setSaveProfileIsOpen, gradientColors } = useGlobalState();
  const navigate = useNavigate();

  const goHome = () => {
    navigate(`/`);
  }

  document.documentElement.style.setProperty('--gradient-color-1', gradientColors[0]);

  return (
    <div className="header">
      <img src={logo} className={isMobile ? "header-logo-mobile" : "header-logo"} alt="logo" onClick={goHome} />
      {user && (
        <div className="user-info">
          <span onClick={() => setSaveProfileIsOpen(true)}>{user.username ? user.username : 'Save'}</span>
        </div>
      )}
      <Profile />
    </div>
  );
}

export default Header;