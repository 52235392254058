import React from 'react';
import './ActiveToggle.css';

function ActiveToggle({ isActive, onChange }) {
  return (
    <label className="switch">
      <input
        type="checkbox"
        checked={isActive}
        onChange={(e) => onChange(e.target.checked)}
      />
      <span className="slider round"></span>
    </label>
  );
}

export default ActiveToggle;
