import React, { useState, useEffect } from 'react';
import ItemModal from './modals/ItemModal';
import ChannelList from './lists/ChannelList';
import MemeList from './lists/MemeList';
import SpectrumList from './lists/SpectrumList';
import BadgeList from './lists/BadgeList';
import DichotomyList from './lists/DichotomyList';
import SelectionList from './lists/SelectionList';
import './CmsLanding.css';
import { useGlobalState } from '../context/GlobalState';
import ComplexItemModal from './modals/ComplexItemModal';

const CmsLanding = ({ socket }) => {
  const [activeList, setActiveList] = useState('channels');
  const [channels, setChannels] = useState([]);
  const [memes, setMemes] = useState([]);
  const [spectrums, setSpectrums] = useState([]);
  const [badges, setBadges] = useState([]);
  const [dichotomies, setDichotomies] = useState([]);
  const [selections, setSelections] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
  const [isModalLoading, setIsModalLoading] = useState(false);

  const { user } = useGlobalState();

  useEffect(() => {
    const handleDataReceived = (dataType, data) => {
      setIsModalLoading(false);
      if (Array.isArray(data)) {
        // If data is an array, replace the entire state
        switch (dataType) {
          case 'channels':
            setChannels(data);
            break;
          case 'memes':
            setMemes(data);
            break;
          case 'spectrums':
            setSpectrums(data);
            break;
          case 'badges':
            setBadges(data);
            break;
          case 'dichotomies':
            setDichotomies(data);
            break;
          case 'selections':
            setSelections(data);
            break;
        }
      } else if (typeof data === 'object' && data !== null) {
        // If data is a single object, add it to the existing state
        switch (dataType) {
          case 'channels':
            setChannels(prevChannels => [data, ...prevChannels]);
            break;
          case 'memes':
            setMemes(prevMemes => [data, ...prevMemes]);
            break;
          case 'spectrums':
            setSpectrums(prevSpectrums => [data, ...prevSpectrums]);
            break;
          case 'badges':
            setBadges(prevBadges => [data, ...prevBadges]);
            break;
          case 'dichotomies':
            setDichotomies(prevDichotomies => [data, ...prevDichotomies]);
            break;
          case 'selections':
            setSelections(prevSelections => [data, ...prevSelections]);
            break;
        }
        setIsCreateModalOpen(false);
        setEditingItem(null);
      }
      setIsLoading(false);
    };

    const handleUpdatedData = (dataType, data) => {
      setIsModalLoading(false);
      switch (dataType) {
        case 'channels':
          setChannels(prevChannels => prevChannels.map(channel => channel._id === data._id ? data : channel));
          break;
        case 'memes':
          setMemes(prevMemes => prevMemes.map(meme => meme._id === data._id ? data : meme));
          break;
        case 'spectrums':
          setSpectrums(prevSpectrums => prevSpectrums.map(spectrum => spectrum._id === data._id ? data : spectrum));
          break;
        case 'badges':
          setBadges(prevBadges => prevBadges.map(badge => badge._id === data._id ? data : badge));
          break;
        case 'dichotomies':
          setDichotomies(prevDichotomies => prevDichotomies.map(dichotomy => dichotomy._id === data._id ? data : dichotomy));
          break;
        case 'selections':
          setSelections(prevSelections => prevSelections.map(selection => selection._id === data._id ? data : selection));
          break;
      }
      setIsCreateModalOpen(false);
      setEditingItem(null);
      setIsLoading(false);
    }

    const handleError = (err) => {
      setError(`Failed to fetch data`);
      console.error(`Error fetching data:`, err);
      setIsLoading(false);
      setIsModalLoading(false);
    };

    socket.on('deliverChannelsAdmin', (data) => handleDataReceived('channels', data));
    socket.on('deliverMemesAdmin', (data) => handleDataReceived('memes', data));
    socket.on('deliverSpectrumsAdmin', (data) => handleDataReceived('spectrums', data));
    socket.on('deliverBadgesAdmin', (data) => handleDataReceived('badges', data));
    socket.on('deliverDichotomiesAdmin', (data) => handleDataReceived('dichotomies', data));
    socket.on('deliverSelectionsAdmin', (data) => handleDataReceived('selections', data));
    socket.on('error-event', handleError);

    socket.on('deliverCreateChannelAdmin', (data) => handleDataReceived('channels', data));
    socket.on('deliverCreateMemeAdmin', (data) => handleDataReceived('memes', data));
    socket.on('deliverCreateSpectrumAdmin', (data) => handleDataReceived('spectrums', data));
    socket.on('deliverCreateBadgeAdmin', (data) => handleDataReceived('badges', data));
    socket.on('deliverCreateDichotomyAdmin', (data) => handleDataReceived('dichotomies', data));
    socket.on('deliverCreateSelectionAdmin', (data) => handleDataReceived('selections', data));

    socket.on('deliverUpdateBadgeAdmin', (data) => handleUpdatedData('badges', data));
    socket.on('deliverUpdateMemeAdmin', (data) => handleUpdatedData('memes', data));
    socket.on('deliverUpdateSpectrumAdmin', (data) => handleUpdatedData('spectrums', data));
    socket.on('deliverUpdateDichotomyAdmin', (data) => handleUpdatedData('dichotomies', data));
    socket.on('deliverUpdateSelectionAdmin', (data) => handleUpdatedData('selections', data));
    socket.on('deliverUpdateChannelAdmin', (data) => handleUpdatedData('channels', data));

    return () => {
      socket.off('deliverChannelsAdmin');
      socket.off('deliverMemesAdmin');
      socket.off('deliverSpectrumsAdmin');
      socket.off('deliverBadgesAdmin');
      socket.off('deliverDichotomiesAdmin');
      socket.off('deliverSelectionsAdmin');
      socket.off('error-event');
      socket.off('deliverCreateChannelAdmin');
      socket.off('deliverCreateMemeAdmin');
      socket.off('deliverCreateSpectrumAdmin');
      socket.off('deliverCreateBadgeAdmin');
      socket.off('deliverCreateDichotomyAdmin');
      socket.off('deliverCreateSelectionAdmin');
      socket.off('deliverUpdateBadgeAdmin');
      socket.off('deliverUpdateMemeAdmin');
      socket.off('deliverUpdateSpectrumAdmin');
      socket.off('deliverUpdateDichotomyAdmin');
      socket.off('deliverUpdateSelectionAdmin');
      socket.off('deliverUpdateChannelAdmin');
    };
  }, [socket]);

  useEffect(() => {
    fetchData();
  }, [activeList]);

  const fetchData = () => {
    setIsLoading(true);
    setError(null);
    const payload = {
      active: true,
    };
    switch (activeList) {
      case 'channels':
        socket.emit('getChannelsAdmin', payload);
        break;
      case 'memes':
        socket.emit('getMemesAdmin', payload);
        break;
      case 'spectrums':
        delete payload.active;
        socket.emit('getSpectrumsAdmin', payload);
        break;
      case 'badges':
        socket.emit('getBadgesAdmin', payload);
        break;
      case 'dichotomies':
        socket.emit('getDichotomiesAdmin', payload);
        break;
      case 'selections':
        socket.emit('getSelectionsAdmin', payload);
        break;
    }
  };

  const handleCreate = () => {
    setIsCreateModalOpen(true);
  };

  const handleCreateSubmit = (data, action) => {
    setIsModalLoading(true);
    setError(null);
    let payload = { ...data };

    if (action === 'create') {
      // Existing logic for create action
      if (activeList === 'memes') {
        payload.creator = user._id;
      }
      // if (['channels', 'memes', 'selections'].includes(activeList)) {
      //   payload.active = data.active !== undefined ? data.active : true;
      // }
    } else if (action === 'update') {
      // Compare data with editingItem and only include changed fields
      payload = Object.keys(data).reduce((changedData, key) => {
        if (JSON.stringify(data[key]) !== JSON.stringify(editingItem[key])) {
          changedData[key] = data[key];
        }
        return changedData;
      }, {});

      // Always include the _id for update operations
      payload._id = editingItem._id;
    }

    console.log({action});
    console.log(payload);

    switch (activeList) {
      case 'channels':
        socket.emit(action === 'create' ? 'createChannelAdmin' : 'updateChannelAdmin', payload);
        break;
      case 'memes':
        socket.emit(action === 'create' ? 'createMemeAdmin' : 'updateMemeAdmin', payload);
        break;
      case 'spectrums':
        socket.emit(action === 'create' ? 'createSpectrumAdmin' : 'updateSpectrumAdmin', payload);
        break;
      case 'badges':
        socket.emit(action === 'create' ? 'createBadgeAdmin' : 'updateBadgeAdmin', payload);
        break;
      case 'dichotomies':
        console.log({payload});
        console.log('shipping dichotomy create')
        socket.emit(action === 'create' ? 'createDichotomyAdmin' : 'updateDichotomyAdmin', payload);
        break;
      case 'selections':
        socket.emit(action === 'create' ? 'createSelectionAdmin' : 'updateSelectionAdmin', payload);
        break;
    }
  };

  const handleItemClick = (item) => {
    setEditingItem(item);
    setIsCreateModalOpen(true);
  };

  const renderList = () => {
    if (isLoading) return <div>Loading...</div>;
    // if (error) return <div>Error: {error}</div>;

    const createButton = (
      <button onClick={handleCreate} className="create-button">
        Create
      </button>
    );

    switch (activeList) {
      case 'channels':
        return <ChannelList channels={channels} onItemClick={handleItemClick} createButton={createButton} />;
      case 'memes':
        return <MemeList memes={memes} onItemClick={handleItemClick} createButton={createButton} />;
      case 'spectrums':
        return <SpectrumList spectrums={spectrums} onItemClick={handleItemClick} createButton={createButton} />;
      case 'badges':
        return <BadgeList badges={badges} onItemClick={handleItemClick} createButton={createButton} />;
      case 'dichotomies':
        return <DichotomyList dichotomies={dichotomies} onItemClick={handleItemClick} createButton={createButton} />;
      case 'selections':
        return <SelectionList selections={selections} onItemClick={handleItemClick} createButton={createButton} />;
      default:
        return <div>Select a list to view</div>;
    }
  };

  const renderModal = () => {
    const isCreating = !editingItem;
    const isComplexCreation = ['selections', 'badges'].includes(activeList) && isCreating;

    if (isComplexCreation) {
      return (
        <ComplexItemModal
          isOpen={isCreateModalOpen}
          onClose={() => {
            setIsCreateModalOpen(false);
            setEditingItem(null);
            setIsModalLoading(false);
          }}
          onSubmit={handleCreateSubmit}
          activeList={activeList}
          isLoading={isModalLoading}
          socket={socket}
        />
      );
    } else {
      return (
        <ItemModal
          isOpen={isCreateModalOpen}
          onClose={() => {
            setIsCreateModalOpen(false);
            setEditingItem(null);
            setIsModalLoading(false);
          }}
          onSubmit={handleCreateSubmit}
          activeList={activeList}
          editingItem={editingItem}
          isLoading={isModalLoading}
          resetForm={!editingItem && !isModalLoading}
        />
      );
    }
  };

  return (
    <div className="cms-landing">
      <div className="cms-sidebar">
        <button
          className={activeList === 'channels' ? 'active' : ''}
          onClick={() => setActiveList('channels')}
        >
          Channels
        </button>
        <button
          className={activeList === 'memes' ? 'active' : ''}
          onClick={() => setActiveList('memes')}
        >
          Memes
        </button>
        <button
          className={activeList === 'spectrums' ? 'active' : ''}
          onClick={() => setActiveList('spectrums')}
        >
          Spectrums
        </button>
        <button
          className={activeList === 'dichotomies' ? 'active' : ''}
          onClick={() => setActiveList('dichotomies')}
        >
          Dichotomies
        </button>
        <button
          className={activeList === 'selections' ? 'active' : ''}
          onClick={() => setActiveList('selections')}
        >
          Selections
        </button>
        <button
          className={activeList === 'badges' ? 'active' : ''}
          onClick={() => setActiveList('badges')}
        >
          Badges
        </button>
      </div>
      <div className="cms-main">
        <div className="cms-search-bar">
          <input type="text" placeholder="Search..." />
          <button>Filter</button>
        </div>
        <div className="cms-content">
          {renderList()}
        </div>
      </div>
      {renderModal()}
    </div>
  );
};

export default CmsLanding;
