import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import LoadingSpinner from '../components/LoadingSpinner';
import ChannelPlayer from '../components/ChannelPlayer';
import ChannelSelector from '../components/ChannelSelector';

import { useGlobalState } from '../context/GlobalState';
import { superSocket } from '../utils/api';
import './WebBuild.css';



const WebBuild = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, setUser, channelsList, channelsMap, handleChannelsList, currentChannelIndex, setCurrentChannelIndex, setCurrentSelectionIndex, gradientColors } = useGlobalState();

  const [isLoading, setIsLoading] = useState(true);
  const [visibleChannels, setVisibleChannels] = useState([]);
  
  const searchParams = new URLSearchParams(location.search);
  const channelId = searchParams.get('cid') || searchParams.get('channel_id');
  const selectionId = searchParams.get('sid') || searchParams.get('selection_id');
  const userShareId = searchParams.get('uid');

  // do something with userShareId?
  // log to database the userId and the user who is clicked the shared link 

  const [urlChannelId, setUrlChannelId] = useState(channelId || null);
  const [urlSelectionId, setUrlSelectionId] = useState(selectionId || null);

  const updateVisibleChannels = useCallback(() => {
    const totalChannels = channelsList.length;
    if (totalChannels < 3) {
      setVisibleChannels([...Array(totalChannels).keys()]);
    } else {
      const prev = (currentChannelIndex - 1 + totalChannels) % totalChannels;
      const next = (currentChannelIndex + 1) % totalChannels;
      setVisibleChannels([prev, currentChannelIndex, next]);
    }
  }, [currentChannelIndex, channelsList]);

  const updateUrlParams = useCallback((channelId, selectionId) => {
    const searchParams = new URLSearchParams(location.search);
    if (channelId) searchParams.set('cid', channelId);
    if (selectionId) searchParams.set('sid', selectionId);
    navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
  }, [location.pathname, navigate]);

  useEffect(() => {
    if (user && channelsList.length && Object.keys(channelsMap).length) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [user, channelsList, channelsMap]);

  useEffect(() => {
    updateVisibleChannels();
  }, [currentChannelIndex, channelsList, updateVisibleChannels]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await checkForUserToken();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

    const handleConnect = () => {
      console.log(`Device connected to users server: ${user}`);
    };

    const handleErrorEvent = (err) => {
      alert(err.message);
    };

    const handleAutoSignInError = () => {
      superSocket.emit('userSignUp', {});
    };

    const handleUpdateResponse = async userData => {
      const userClone = { ...user, ...userData };
      setUser(userClone);
      getChannels(userClone, urlChannelId, urlSelectionId);
      setIsLoading(false);
    };

    const handleSignInAuto = async userPayload => {
      localStorage.setItem('token', userPayload.token);
      localStorage.setItem('user', userPayload._id.toString());
      setUser(userPayload);
      getChannels(userPayload, urlChannelId, urlSelectionId);
      setIsLoading(false);
    };

    const handleSignUp = async userPayload => {
      localStorage.setItem('token', userPayload.token);
      localStorage.setItem('user', userPayload._id.toString());
      setUser(userPayload);
      getChannels(userPayload, urlChannelId, urlSelectionId);
      setIsLoading(false);
    };

    const handleChannels = async channels => {
      handleChannelsList(channels);
      const initialChannelId = channels[0].channel_id;
      const initialSelectionId = channels[0].selections[0]?.selection_id;
      updateUrlParams(initialChannelId, initialSelectionId);
    };

    superSocket.on('connect', handleConnect);
    superSocket.on('error-event', handleErrorEvent);
    superSocket.on('auto-sign-in-error', handleAutoSignInError);
    superSocket.on('deliverUserSignUp', handleSignUp);
    superSocket.on('deliverUserSignInAuto', handleSignInAuto);
    superSocket.on('deliverUpdateProfileRes', handleUpdateResponse);
    superSocket.on('deliverChannelsList', handleChannels);

    return () => {
      superSocket.off('connect', handleConnect);
      superSocket.off('error-event', handleErrorEvent);
      superSocket.off('auto-sign-in-error', handleAutoSignInError);
      superSocket.off('deliverUserSignUp', handleSignUp);
      superSocket.off('deliverUserSignInAuto', handleSignInAuto);
      superSocket.off('deliverUpdateProfileRes', handleUpdateResponse);
      superSocket.off('deliverChannelsList', handleChannels);
      superSocket.disconnect();
    };
  }, []);

  const checkForUserToken = async () => {
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('user');

    if (!token || !userId) {
      superSocket.emit('userSignUp', {});
      return;
    }

    const auth = { token, userId };
    const payload = { user: userId, token };

    superSocket.emit('signInAuto', payload, auth);
  }

  const getChannels = async (user, channelId = null, selectionId = null) => {
    const payload = {
      user: user._id,
      channel_id: channelId || urlChannelId,
      selection_id: selectionId || urlSelectionId
    };

    superSocket.emit('getChannels', payload);
  }

  const changeChannel = useCallback((direction) => {
    let newIndex;
    if (direction === 'next') {
      newIndex = (currentChannelIndex + 1) % channelsList.length;
    } else if (direction === 'prev') {
      newIndex = (currentChannelIndex - 1 + channelsList.length) % channelsList.length;
    } else {
      newIndex = direction; // If a specific index is provided
    }

    setCurrentSelectionIndex(0);
    setCurrentChannelIndex(newIndex);

    // Update URL parameters
    const newChannelId = channelsList[newIndex];
    const newSelectionId = channelsMap[newChannelId].selections[0]?.selection_id;
    updateUrlParams(newChannelId, newSelectionId);

    // Update state
    setUrlChannelId(newChannelId);
    setUrlSelectionId(newSelectionId);
  }, [currentChannelIndex, channelsList, channelsMap, updateUrlParams]);

  const updateSelection = useCallback((newSelectionIndex) => {
    setCurrentSelectionIndex(newSelectionIndex);

    // Update URL parameters
    const currentChannelId = channelsList[currentChannelIndex];
    const currentSelection = channelsMap[currentChannelId].selections[newSelectionIndex];
    updateUrlParams(currentChannelId, currentSelection.selection_id);

    // Update state
    setUrlChannelId(currentChannelId);
    setUrlSelectionId(currentSelection.selection_id);
  }, [currentChannelIndex, channelsList, channelsMap, updateUrlParams]);

  useEffect(() => {
    const updateContentHeight = () => {
      const header = document.querySelector('.header');
      if (header) {
        const headerHeight = header.offsetHeight;
        document.documentElement.style.setProperty('--header-height', `${headerHeight}px`);
      }
    };

    updateContentHeight();
    window.addEventListener('resize', updateContentHeight);

    return () => window.removeEventListener('resize', updateContentHeight);
  }, []);

  if (isLoading || !channelsList.length || !Object.keys(channelsMap).length) {
    return <LoadingSpinner />;
  } else {
    return (
      <div className="web-build" style={{
        background: `linear-gradient(to bottom, ${gradientColors[0]}, ${gradientColors[1]})`,
      }}>
        <ChannelPlayer 
          onChannelChange={changeChannel}
          onSelectionChange={updateSelection}
        />
        <div className="channel-selector-wrapper">
          <ChannelSelector 
            visibleChannels={visibleChannels}
            activeChannel={currentChannelIndex}
            onChannelChange={changeChannel}
          />
        </div>
      </div>
    );
  }
};

export default WebBuild;
