import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import { isMobile } from 'react-device-detect';

import { GlobalStateProvider } from './context/GlobalState';

import './App.css';

import WebBuild from './screens/WebBuild';
import Home from './screens/Home';
import Header from './components/Header';
import Terms from './screens/Terms';
import Privacy from './screens/Privacy';
import Contact from './screens/Contact';
import Forgot from './screens/Forgot';
import Redirect from './screens/Redirect';
import Cmsui from './screens/Cmsui.js';

function App() {
  return (
    <GlobalStateProvider>
      <div className="App">
        <Router>
          <Header isMobile={isMobile} />
          <Routes>
            <Route path="/" element={<WebBuild isMobile={isMobile} />} />
            <Route path="/landing" element={<Home isMobile={isMobile} />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/forgot" element={<Forgot />} />
            <Route path="/redirect" element={<Redirect />} />
            <Route path="/cmsui" element={<Cmsui />} />
          </Routes>
        </Router>
      </div>
    </GlobalStateProvider>
  );
}

export default App;
