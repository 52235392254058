import emoji from 'emoji-dictionary';

export const renderEmoji = (emojiName) => {
  if (!emojiName || typeof emojiName !== 'string') {
    return '';
  }
  try {
    const cleanEmojiName = emojiName.replace(/:/g, '');
    return emoji.getUnicode(cleanEmojiName) || `:${cleanEmojiName}:`;
  } catch (error) {
    console.error('Error rendering emoji:', error);
    return emojiName;
  }
};
