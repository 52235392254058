// TODO: replace with a more sophisticated algorithm
// right now this allows a 5 and 2 to return on a 80 20 split instead of a 5 and 1 or 4 and 2.
const calculateStars = (value) => {
  if (value >= 80) return 5;
  if (value >= 60) return 4;
  if (value >= 40) return 3;
  if (value >= 20) return 2;
  return 1;

  // if (value >= 90) return 10;
  // if (value >= 80) return 9;
  // if (value >= 70) return 8;
  // if (value >= 60) return 7;
  // if (value >= 50) return 6;
  // if (value >= 40) return 5;
  // if (value >= 30) return 4;
  // if (value >= 20) return 3;
  // if (value >= 10) return 2;
  // return 1;
};

export default calculateStars;