import React, { useMemo, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useGlobalState } from '../context/GlobalState';
import calculateStars from '../utils/calculateStars';
import getEmojiFromString from '../utils/getEmojiFromString';
import './BadgeSummary.css'; // Import the CSS file

const BadgeSummary = ({ visible, onClose, onChannelChange }) => {
  const location = useLocation();
  const { user, channelsList, channelsMap, currentChannelIndex, currentSelectionIndex, gradientColors } = useGlobalState();

  const channel = useMemo(() => channelsMap[channelsList[currentChannelIndex]], [channelsMap, channelsList, currentChannelIndex]);
  const badges = useMemo(() => channel?.badges || [], [channel?.badges]);

  const searchParams = new URLSearchParams(location.search);
  const channelId = searchParams.get('cid') || searchParams.get('channel_id');
  const selectionId = channel?.selections[0].selection_id;

  const title = channel?.channel_title.toUpperCase();

  const badgeDisplays = useMemo(() => {
    const badgeDisplayArray = badges.reduce((acc, badge) => {
      const winnerValue = Math.round(badge.score1);
      const winnerStars = '⭐'.repeat(calculateStars(winnerValue));
      const winnerEmoji = getEmojiFromString(badge.emoji1);
      const winnerLabel = badge.label1.toUpperCase();
      const loserValue = Math.round(badge.score2);
      const loserStars = '⭐'.repeat(calculateStars(loserValue));
      const loserEmoji = getEmojiFromString(badge.emoji2);
      const loserLabel = badge.label2.toUpperCase();
      const winner = {
        score: winnerValue,
        stars: winnerStars,
        emoji: winnerEmoji,
        label: winnerLabel,
        definition: badge.definition1,
      }
      const loser = {
        score: loserValue,
        stars: loserStars,
        emoji: loserEmoji,
        label: loserLabel,
        definition: badge.definition2,
      }
      if (winner.score > 0) {
        acc.push(winner);
      }
      if (loser.score > 0) {
        acc.push(loser);
      }
      return acc;
    }, []);

    // sort the array so that the highest number of stars is first
    badgeDisplayArray.sort((a, b) => b.score - a.score);
    return badgeDisplayArray;
  }, [badges]);

  const badgeSummary = useMemo(() => {
    if (!channel || !channel.badges) return '';
    
    return badgeDisplays.reduce((acc, badge) => {
      const name = badge.label.toUpperCase();
      const stars = calculateStars(badge.score);
      const emoji = badge.emoji;
      return acc + `\n${emoji} ${name} ${emoji} - ${'⭐'.repeat(stars)}`;
    }, '');
  }, [channel?.badges]);

  const [showCopyNotification, setShowCopyNotification] = useState(false);

  useEffect(() => {
    if (showCopyNotification) {
      const timer = setTimeout(() => {
        setShowCopyNotification(false);
      }, 3000); // Hide notification after 3 seconds

      return () => clearTimeout(timer);
    }
  }, [showCopyNotification]);

  const handleShare = () => {
    const introLanguage = 'Check out my ranks in Super Mega World!';
    const url = `https://supermega.world/?uid=${user._id}&cid=${channelId}${selectionId ? `&sid=${selectionId}` : ''}`;
    const message = `${introLanguage}\n\n${title}\n${badgeSummary}\n\nGet Ranked: ${url}`;

    navigator.clipboard.writeText(message).then(() => {
      setShowCopyNotification(true);
    }).catch(console.error);
  };

  const handleNext = () => {
    onChannelChange('next');
    onClose();
  }

  // Set CSS variables for gradient colors
  document.documentElement.style.setProperty('--gradient-color-1', gradientColors[0]);
  document.documentElement.style.setProperty('--gradient-color-2', gradientColors[1]);

  if (!visible) return null;

  return (
    <div className="modal">
      <div className="modal-content custom-modal">
        <div className="modal-header">
          <h2>Your Badges</h2>
          <button onClick={onClose}>&times;</button>
        </div>
        <div className="modal-body">
          <div className="badge-summary">
            {badgeDisplays.map((badge, index) => {
              const value = Math.round(badge.score);
              const stars = '⭐'.repeat(calculateStars(value));
              return (
                <div key={index} className="badge-item">
                  <div className="badge-header">
                    <span className="badge-emoji">{badge.emoji}</span>
                    <div className="badge-info">
                      <span className="badge-label">{badge.label.toUpperCase()}</span>
                      <span className="badge-stars">{stars}</span>
                    </div>
                    <span className="badge-emoji">{badge.emoji}</span>
                  </div>
                  <p className="badge-description">{badge.definition}</p>
                </div>
              )
            })}
          </div>
        </div>
        <div className="modal-footer d-flex justify-content-center">
          {
            showCopyNotification 
            ?
            <button className="btn btn-secondary">Copied!</button>
            :
            <button className="btn btn-secondary" onClick={handleShare}>Share</button>
          }
          {currentSelectionIndex === channel.selections.length - 1 && (
            <button className="btn btn-primary" onClick={handleNext}>Next</button>
          )}
        </div>
      </div>

    </div>
  );
}

export default BadgeSummary;
