import React from 'react';
import { useGlobalState } from '../context/GlobalState';
import './ChannelSelector.css';

const ChannelSelector = ({ visibleChannels, activeChannel, onChannelChange }) => {
  const { channelsList, channelsMap } = useGlobalState();

  return (
    <div className="channel-selector-container">
      <div className="channel-selector">
        {visibleChannels.map((channel) => {
          const c = channelsMap[channelsList[channel]];
          return (
            <div
              key={channel}
              className={`channel-item ${channel === activeChannel ? 'active' : ''}`}
              onClick={() => onChannelChange(channel)}
            >
              <span className="channel-name">{c.channel_title}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ChannelSelector;
