import axios from "axios";
import * as R from 'ramda';
import io from 'socket.io-client';

const BASE = process.env.REACT_APP_API_BASE;
const SUPERSOCKET = process.env.REACT_APP_SOCKET_URL;

const superSocket = io(SUPERSOCKET);
// const playSocket = io(PLAYSOCKET);

// console.log({BASE, SUPERSOCKET});

const API_VERSION = '0.0.1';

const globalConfig = {
  headers: { 
    'Accept-Version': API_VERSION,
  }
};

/**
 * custom rule for local handling for any routes pointed to new services 
 * production is routed via nginx
 */ 
function constructEndpoint(route) {
    if (BASE.indexOf('localhost') === -1) return `${BASE}${route}`
    else return `${BASE}:3001${route}`;
}

async function post(route, payload, config = {}) {
  const endpoint = constructEndpoint(route);
  const finalConfig = R.mergeDeepWith(R.concat, globalConfig, config);
  const response = await axios.post(endpoint, payload, finalConfig)
    .catch((err) => {
      if (err.response && err.response.data) throw new Error(err.response.data);
      throw new Error(err);
    });
  return response.data;
}

async function get(route, config = {}) {
  const endpoint = constructEndpoint(route);
  const finalConfig = R.mergeDeepWith(R.concat, globalConfig, config);
  const response = await axios.get(endpoint, finalConfig)
    .catch(err => {
      if (err.response && err.response.data) throw new Error(err.response.data);
      throw new Error(err);
    });
  return response.data;
}

export { 
  post, 
  get,
  superSocket,
};