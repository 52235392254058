import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import LoadingSpinner from '../components/LoadingSpinner';
import { superSocket } from '../utils/api';

import logo from '../logo.png';

import './Forgot.css';

const Forgot = ({ isMobile }) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const token = query.get('token'); // Assuming the URL parameter is named 'token'
  const userId = query.get('user_id'); 

  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordRepeated, setPasswordRepeated] = useState('');
  const [success, setSuccess] = useState(false);

  const passwordMatch = password === passwordRepeated;

  useEffect(() => {
    const handleUpdateSuccess = async success => {
      if (!success) {
        alert(`Error updating password`);
        return;
      }
      
      alert(`password updated successfully!`);
      setIsLoading(false);
      setSuccess(true);
    };

    const handleFailure = (err) => {
      alert(`${err.message}`);
      setIsLoading(false);
    };

    superSocket.on(`deliverPasswordUpdateResponse`, handleUpdateSuccess);
    superSocket.on(`updatePasswordFailure`, handleFailure);
  }, []); 

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handlePasswordRepeatedChange = (e) => {
    setPasswordRepeated(e.target.value);
  };

  const submitNewPassword = async () => {
    if (!passwordMatch) {
      alert('Passwords do not match');
      return;
    }

    setIsLoading(true);

    if (password === '') {
      alert('Username and password are required!');
      setIsLoading(false);
      return;
    }

    const payload = { user: userId, password, token };
    superSocket.emit('updatePassword', payload);
  }

  if (success) {
    return (
      <div className={isMobile ? "home-container-mobile" : "home-container"}>
        <div className={isMobile ? 'card-mobile' : 'card'}>
          <img src={logo} className={isMobile ? "home-logo-mobile" : "home-logo"} alt="logo" />
          <div className={'signin-form'}>
            <p className="title" >Password updated successfully!</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={isMobile ? "home-container-mobile" : "home-container"}>
      {
        isLoading
        ?
        <LoadingSpinner />
        :
        <div className={isMobile ? 'card-mobile' : 'card'}>
          <div className={'column'}>
            <p className="directions" >Choose your new password</p>
            <input className={'input'} type="password" value={password} onChange={handlePasswordChange} placeholder="New password" />
            <input className={'input'} type="password" value={passwordRepeated} onChange={handlePasswordRepeatedChange} placeholder="Repeat password" />
            <br />
            <button onClick={submitNewPassword} className={'button'}>{'Submit'}</button>
          </div>
        </div>
      }
    </div>
  );
};

export default Forgot;