import React from 'react';
import ActiveToggle from '../ActiveToggle';
import '../CmsLanding.css';

// dichotomy_id: Joi.number().required(),
// selection_id: Joi.number().required(),
// dichotomy_value1_spectrum_value1: Joi.boolean().required(),
// active: Joi.boolean().default(true)

const BadgeList = ({ badges, onItemClick }) => {
  return (
    <>
      <table className="cms-table badges-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Active</th>
            <th>Dichotomy ID</th>
            <th>Selection ID</th>
            <th>Value1 Spectrum1 Alignment</th>
          </tr>
        </thead>
        <tbody>
          {badges.map(badge => (
            <tr key={badge._id} onClick={() => onItemClick(badge)}>
              <td>{badge._id}</td>
              <td>{badge.active ? 'True' : 'False'}</td>
              <td>{badge.dichotomy_id}</td>
              <td>{badge.selection_id}</td>
              <td>{badge.dichotomy_value1_spectrum_value1 ? 'True' : 'False'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default BadgeList;

