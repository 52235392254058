import React from 'react';
import '../CmsLanding.css';

// value1: Joi.string().max(50).required(),
// value2: Joi.string().max(50).required(),
// active: Joi.boolean().default(true)

const SpectrumList = ({ spectrums, onItemClick, createButton }) => {
  return (
    <>
      {createButton}
      <table className="cms-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Value 1</th>
            <th>Value 2</th>
          </tr>
        </thead>
        <tbody>
          {spectrums.map(spectrum => (
            <tr key={spectrum._id} onClick={() => onItemClick(spectrum)}>
              <td>{spectrum._id}</td>
              <td>{spectrum.value1}</td>
              <td>{spectrum.value2}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default SpectrumList;
