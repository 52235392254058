import React from 'react';
import '../CmsLanding.css';

// title: Joi.string().max(100).required(),
// description: Joi.string().max(255),
// color: Joi.string().max(10),
// gradient_start: Joi.string().max(10),
// gradient_end: Joi.string().max(10),
// type: Joi.string().max(30).required(),
// active: Joi.boolean().default(true)

const ChannelList = ({ channels, onItemClick, createButton }) => {
  return (
    <>
      {createButton}
      <table className="cms-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Active</th>
            <th>Title</th>
            <th>Description</th>
            <th>Type</th>
            <th>Color</th>
            <th>Gradient Start</th>
            <th>Gradient End</th>
          </tr>
        </thead>
        <tbody>
          {channels.map(channel => (
            <tr key={channel._id} onClick={() => onItemClick(channel)}>
              <td>{channel._id}</td>
              <td>{channel.active ? 'True' : 'False'}</td>
              <td>{channel.title}</td>
              <td>{channel.description}</td>
              <td>{channel.type}</td>
              <td>
                <div className="color-preview" style={{backgroundColor: channel.color}}></div>
                {channel.color}
              </td>
              <td>
                <div className="color-preview" style={{backgroundColor: channel.gradient_start}}></div>
                {channel.gradient_start}
              </td>
              <td>
                <div className="color-preview" style={{backgroundColor: channel.gradient_end}}></div>
                {channel.gradient_end}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default ChannelList;
