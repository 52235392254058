import React, { useState, useEffect } from 'react';
import DropdownSelect from '../DropdownSelect';
import ActiveToggle from '../ActiveToggle';
import '../CmsLanding.css';

// Add this import at the top of your file
import { FaTimes } from 'react-icons/fa';

const ComplexItemModal = ({ isOpen, onClose, onSubmit, activeList, isLoading, socket }) => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({ active: true });
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [options, setOptions] = useState({
    channels: [],
    memes: [],
    spectrums: [],
    dichotomies: [],
    selections: []
  });
  const [selectedItems, setSelectedItems] = useState({});
  const [badges, setBadges] = useState([]);
  const [dichotomyKey, setDichotomyKey] = useState(0);

  const resetForm = () => {
    setFormData({ active: true });
    setSelectedItems({});
    setBadges([]);
    setStep(1);
  };

  useEffect(() => {
    if (isOpen) {
      fetchOptions();
    }
  }, [isOpen]);

  const fetchOptions = () => {
    const payload = {
      active: true
    }
    socket.emit('getChannelsAdmin', payload);
    socket.emit('getMemesAdmin', payload);
    socket.emit('getSpectrumsAdmin', {});
    socket.emit('getDichotomiesAdmin', payload);
    socket.emit('getSelectionsAdmin', payload);

    socket.on('deliverChannelsAdmin', (data) => setOptions(prev => ({ ...prev, channels: data })));
    socket.on('deliverMemesAdmin', (data) => setOptions(prev => ({ ...prev, memes: data })));
    socket.on('deliverSpectrumsAdmin', (data) => setOptions(prev => ({ ...prev, spectrums: data })));
    socket.on('deliverDichotomiesAdmin', (data) => setOptions(prev => ({ ...prev, dichotomies: data })));
    socket.on('deliverSelectionsAdmin', (data) => setOptions(prev => ({ ...prev, selections: data })));

    return () => {
      socket.off('deliverChannelsAdmin');
      socket.off('deliverMemesAdmin');
      socket.off('deliverSpectrumsAdmin');
      socket.off('deliverDichotomiesAdmin');
      socket.off('deliverSelectionsAdmin');
    };
  };

  const handleChange = (name, value) => {
    setFormData(prev => ({ ...prev, [name]: value }));
    setSelectedItems(prev => ({ ...prev, [name]: value }));
    if (name !== 'dichotomy_id' && name !== 'dichotomy_value1_spectrum_value1') {
      handleNext();
    }
  };

  const renderSelectionSummary = () => {
    return (
      <div className="selection-summary">
        {selectedItems.channel_id && <p>Channel: {options.channels.find(c => c._id === selectedItems.channel_id)?.title}</p>}
        {selectedItems.spectrum_id && <p>Spectrum: {options.spectrums.find(s => s._id === selectedItems.spectrum_id)?.value1} - {options.spectrums.find(s => s._id === selectedItems.spectrum_id)?.value2}</p>}
        {selectedItems.meme_id && <p>Meme: {options.memes.find(m => m._id === selectedItems.meme_id)?.text || options.memes.find(m => m._id === selectedItems.meme_id)?.url}</p>}
        <div className="form-field selection-active-toggle">
          <label>Active</label>
          <ActiveToggle
            isActive={formData.active}
            onChange={(value) => handleChange('active', value)}
          />
        </div>
      </div>
    );
  };

  const renderSelectionForm = () => {
    const commonProps = {
      onChange: (selected) => handleChange(step === 1 ? 'channel_id' : step === 2 ? 'spectrum_id' : 'meme_id', selected.value),
    };

    if (step === 1) {
      return (
        <div className="form-field">
          <label htmlFor="channel">Select Channel</label>
          <DropdownSelect
            key={`channel-${step}`}
            {...commonProps}
            options={options.channels.map(channel => ({ value: channel._id, label: channel.title }))}
            placeholder="Choose a channel"
          />
        </div>
      );
    }

    if (step === 2) {
      return (
        <div className="form-field">
          <label htmlFor="spectrum">Select Spectrum</label>
          <DropdownSelect
            key={`spectrum-${step}`}
            {...commonProps}
            options={options.spectrums.map(spectrum => ({ value: spectrum._id, label: `${spectrum.value1} - ${spectrum.value2}` }))}
            placeholder="Choose a spectrum"
          />
        </div>
      );
    }

    if (step === 3) {
      return (
        <div className="form-field">
          <label htmlFor="meme">Select Meme</label>
          <DropdownSelect
            key={`meme-${step}`}
            {...commonProps}
            options={options.memes.map(meme => ({ value: meme._id, label: meme.text || meme.url }))}
            placeholder="Choose a meme"
          />
        </div>
      );
    }

    if (step === 4) {
      const selectedSpectrum = options.spectrums.find(s => s._id === selectedItems.spectrum_id);
      return (
        <>
          <div className="form-field">
            <label htmlFor="dichotomy">Select Dichotomy</label>
            <DropdownSelect
              key={`dichotomy-${step}-${dichotomyKey}`}
              options={options.dichotomies.map(dichotomy => ({ value: dichotomy._id, label: `${dichotomy.value1} - ${dichotomy.value2}` }))}
              onChange={(selected) => handleChange('dichotomy_id', selected.value)}
              value={formData.dichotomy_id}
              placeholder="Choose a dichotomy"
            />
          </div>
          <div className="form-field selection-active-toggle">
            <label>Dichotomy Value 1 Spectrum Value 1 Alignment</label>
            <ActiveToggle
              isActive={formData.dichotomy_value1_spectrum_value1 || false}
              onChange={(value) => handleChange('dichotomy_value1_spectrum_value1', value)}
            />
          </div>
          <button onClick={handleAddBadge} disabled={!formData.dichotomy_id}>
            Add
          </button>
          {badges.length > 0 && (
            <div className="badges-summary">
              <h3>Badges:</h3>
              {badges.map((badge, index) => {
                const dichotomy = options.dichotomies.find(d => d._id === badge.dichotomy_id);
                return (
                  <div key={index} className="badge-item">
                    <div className="badge-content">
                      <ul>
                        <li>{badge.dichotomy_value1_spectrum_value1 ? dichotomy.value1 : dichotomy.value2} is {selectedSpectrum.value1}</li>
                        <li>{badge.dichotomy_value1_spectrum_value1 ? dichotomy.value2 : dichotomy.value1} is {selectedSpectrum.value2}</li>
                      </ul>
                    </div>
                    <button 
                      type="button" 
                      className="remove-badge-btn"
                      onClick={() => handleRemoveBadge(index)}
                      aria-label="Remove badge"
                    >
                      <FaTimes />
                    </button>
                  </div>
                );
              })}
            </div>
          )}
        </>
      );
    }
  };

  const handleAddBadge = () => {
    // console.log('formData', formData);
    if (formData.dichotomy_id) {
      setBadges([...badges, {
        dichotomy_id: formData.dichotomy_id,
        dichotomy_value1_spectrum_value1: formData.dichotomy_value1_spectrum_value1 || false
      }]);
      setFormData(prev => ({
        ...prev,
        dichotomy_id: '',
        dichotomy_value1_spectrum_value1: false
      }));
      setDichotomyKey(prevKey => prevKey + 1); // Increment the key
    }
  };

  const handleRemoveBadge = (index) => {
    setBadges(prev => prev.filter((badge, i) => i !== index));
  };

  const handleSubmit = async () => {
    setIsModalLoading(true);
    try {
      // Create selection
      const selectionPayload = {
        channel_id: selectedItems.channel_id,
        spectrum_id: selectedItems.spectrum_id,
        meme_id: selectedItems.meme_id,
        active: formData.active
      };

      const selectionResponse = await new Promise((resolve, reject) => {
        const handleSelectionCreated = (data) => {
          socket.off('deliverCreateSelectionAdmin', handleSelectionCreated);
          if (data.error) {
            reject(data.error);
          } else {
            resolve(data);
          }
        };

        socket.on('deliverCreateSelectionAdmin', handleSelectionCreated);
        socket.emit('createSelectionAdmin', selectionPayload);
      });

      // Create badges
      for (const badge of badges) {
        const badgePayload = {
          ...badge,
          selection_id: selectionResponse._id,
          active: true
        };

        await new Promise((resolve, reject) => {
          const handleBadgeCreated = (data) => {
            socket.off('deliverCreateBadgeAdmin', handleBadgeCreated);
            if (data.error) {
              reject(data.error);
            } else {
              resolve(data);
            }
          };

          socket.on('deliverCreateBadgeAdmin', handleBadgeCreated);
          socket.emit('createBadgeAdmin', badgePayload);
        });
      }

      onClose();
      setFormData({ active: true });
      setSelectedItems({});
      setBadges([]);
      setStep(1);
    } catch (error) {
      console.error('Error creating selection and badges:', error);
      // Handle error (e.g., show error message to user)
    } finally {
      setIsModalLoading(false);
    }
  };

  const handleNext = () => {
      setStep(step + 1);
  };

  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Create Selection and Badges</h2>
        {renderSelectionSummary()}
        <form className="complex-modal-form">
          {renderSelectionForm()}
          <div className="modal-actions">
            {step > 1 && <button type="button" onClick={handleBack} disabled={isLoading}>Back</button>}
            {/* {step < 4 && <button type="button" onClick={handleNext} disabled={isLoading}>Next</button>} */}
            {step === 4 && <button type="button" onClick={handleSubmit} disabled={isLoading}>Submit</button>}
            <button type="button" onClick={() => {
              resetForm();
              onClose();
            }} disabled={isLoading}>Cancel</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ComplexItemModal;
