import React, { useEffect } from 'react';
import { domain, deeplink, ios_link, android_link } from '../utils/constants';

import './Redirect.css';

const RedirectPage = () => {
  useEffect(() => {
    const redirect = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      const channelId = new URLSearchParams(window.location.search).get('cid');

      // Define your deeplink and app store URLs
      const deeplinkUrl = `${deeplink}home?channel=${channelId}`;

      // Detect iOS
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        setTimeout(() => {
          window.location.href = ios_link;
        }, 1500); // Redirect to App Store after 1.5 seconds if app is not opened
        window.location.href = deeplinkUrl;
      } 
      // Detect Android
      else if (/android/i.test(userAgent)) {
        setTimeout(() => {
          window.location.href = android_link;
        }, 1500); // Redirect to Google Play Store after 1.5 seconds if app is not opened
        window.location.href = deeplinkUrl;
      } 
      // Fallback for non-mobile devices
      else {
        window.location.href = domain;
      }
    };

    redirect();
  }, []);

  return (
    <div className="redirect">
      <p>Redirecting...</p>
    </div>
  );
};

export default RedirectPage;