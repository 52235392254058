import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGlobalState } from '../context/GlobalState';
import { superSocket } from '../utils/api';
import './Cmsui.css';

import SignInForm from '../components/SignInForm';
import CmsLanding from '../components/CmsLanding';

const Cmsui = () => {
  const { user, signInUser } = useGlobalState();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleSignInSuccess = (userData) => {
      signInUser(userData);
      // localStorage.setItem('user', userData._id);
      // localStorage.setItem('token', userData.token);
      setIsLoading(false);
    };

    const handleErrorEvent = (error) => {
      console.log({ error });
      alert(error.message);
      setIsLoading(false);
    };

    superSocket.on('deliverUserSignInAdmin', handleSignInSuccess);
    superSocket.on('error-event', handleErrorEvent);

    return () => {
      superSocket.off('deliverUserSignInAdmin', handleSignInSuccess);
      superSocket.off('error-event', handleErrorEvent);
    };
  }, []);

  const handleSignIn = (username, password) => {
    setIsLoading(true);
    superSocket.emit('signInAdmin', { username, password });
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="cmsui-container">
      <div className="cmsui-content">
        {user ? (
          <CmsLanding socket={superSocket} />
        ) : (
          <SignInForm onSignIn={handleSignIn} />
        )}
      </div>
    </div>
  );
};

export default Cmsui;
