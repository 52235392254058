
module.exports = {
  app_name: 'Super Mega',
  tag_line: 'Who are you?',
  domain: 'https://supermega.world',
  deeplink: 'supermega://',
  ios_link: 'https://apps.apple.com/us/app/golfer-royale/id6467991220',
  android_link: 'https://play.google.com/store/apps/details?id=xyz.chipsgolf.chips',
  company_name: 'Super Mega, Inc',
  support_email: 'support@supermega.world',
  info_email: 'info@supermega.world',
  privacy_url: 'https://supermega.world/privacy',
  twitter_url: 'https://twitter.com/golferroyale',
  substack_url: 'https://golferroyale.substack.com/',
  instagram_url: 'https://www.instagram.com/golferroyale/',
  tiktok_url: 'https://www.tiktok.com/@golfer.royale',
}