import React, { useState, useEffect, useMemo, useCallback } from 'react';
import ReactSlider from 'react-slider';
import { useGlobalState } from '../context/GlobalState';
import { superSocket } from '../utils/api';
import calculateStars from '../utils/calculateStars';
import getEmojiFromString from '../utils/getEmojiFromString';
import BadgeSummary from './BadgeSummary';
// Add these imports
// import { FaClipboard, FaClipboardCheck } from 'react-icons/fa';
import { FaStar } from 'react-icons/fa'; // Add this import

import './ChannelPlayer.css';

const ChannelPlayer = ({ onChannelChange, onSelectionChange }) => {
  const { user, channelsMap, setChannelsMap, channelsList, currentChannelIndex, currentSelectionIndex, setCurrentSelectionIndex, badgeModalIsOpen, setBadgeModalIsOpen, setGradientColors } = useGlobalState();
  
  const channel = useMemo(() => channelsMap[channelsList[currentChannelIndex]], [channelsMap, channelsList, currentChannelIndex]);
  const selection = useMemo(() => channel.selections[currentSelectionIndex], [channel, currentSelectionIndex]);
  const badges = useMemo(() => channel?.badges || [], [channel?.badges]);

  const [shareSuccess, setShareSuccess] = useState(false);
  const [sliderValue, setSliderValue] = useState(selection.user_rating === null ? 0 : selection.user_rating);
  const [isAdvancedMode, setIsAdvancedMode] = useState(false);

  const badgeDisplays = useMemo(() => {
    const badgeDisplayArray = badges.reduce((acc, badge) => {
      const winnerValue = Math.round(badge.score1);
      const winnerStars = '⭐'.repeat(calculateStars(winnerValue));
      const winnerEmoji = getEmojiFromString(badge.emoji1);
      const winnerLabel = badge.label1.toUpperCase();
      const loserValue = Math.round(badge.score2);
      const loserStars = '⭐'.repeat(calculateStars(loserValue));
      const loserEmoji = getEmojiFromString(badge.emoji2);
      const loserLabel = badge.label2.toUpperCase();
      const winner = {
        score: winnerValue,
        stars: winnerStars,
        emoji: winnerEmoji,
        label: winnerLabel,
      }
      const loser = {
        score: loserValue,
        stars: loserStars,
        emoji: loserEmoji,
        label: loserLabel,
      }
      if (winner.score > 0) {
        acc.push(winner);
      }
      if (loser.score > 0) {
        acc.push(loser);
      }
      return acc;
    }, []);

    // sort the array so that the highest number of stars is first
    badgeDisplayArray.sort((a, b) => b.score - a.score);
    return badgeDisplayArray;
  }, [badges]);

  useEffect(() => {
    if (selection) {
      setSliderValue(selection.user_rating === null ? 0 : selection.user_rating);
    }
  }, [channel, currentSelectionIndex]);

  useEffect(() => {
    const handleRatingChange = async response => {
      // update local state to reflect the change
      const channelClone = channelsMap[response.channel];
      const responseSelectionIndex = channelClone.selections.findIndex(selection => selection.selection_id === response.selection);
      channelClone.selections[responseSelectionIndex].user_rating = response.user_rating;
      channelClone.badges = response.badges;
      setChannelsMap({ ...channelsMap, [response.channel]: channelClone });

      // add a time delay to the next proposition
      setTimeout(() => {        
        if (responseSelectionIndex < channel.selections.length - 1) {
          nextProposition();
        } else {
          setBadgeModalIsOpen(true);
        }
      }, 1000);
    }

    superSocket.on('deliverRatingResponse', handleRatingChange);
    return () => {
      superSocket.off('deliverRatingResponse', handleRatingChange);
    };
  }, [currentSelectionIndex, channel]);

  const renderBadges = (badges) => {
    return badges.map((badge, index) => (
      <span key={index} className={`badge`}>
        <span className="stars">{badge.stars}</span>
        <span className="emoji">{badge.emoji}</span>
        <span className="emoji-label">{badge.label}</span>
      </span>
    ));
  };

  const handleSliderChange = (value) => {
    setSliderValue(value);
  };

  const handleSliderComplete = (value) => {
    const payload = {
      user: user._id,
      channel: channel.channel_id,
      selection: selection.selection_id,
      rating: value
    };
    superSocket.emit('addRating', payload);
  };

  const handleStarClick = (starValue) => {
    const ratingMap = { 1: 10, 2: 30, 3: 50, 4: 70, 5: 90 };
    const newValue = ratingMap[starValue];
    setSliderValue(newValue);
    handleSliderComplete(newValue);
  };

  const renderStarRating = () => {
    const starCount = Math.round(sliderValue / 20);
    return (
      <div className="star-rating">
        {[1, 2, 3, 4, 5].map((star) => (
          <FaStar
            key={star}
            className={star <= starCount ? "star filled" : "star"}
            onClick={() => handleStarClick(star)}
          />
        ))}
      </div>
    );
  };

  const nextProposition = useCallback(() => {
    if (currentSelectionIndex < channel.selections.length - 1) {
      const nextIndex = currentSelectionIndex + 1;
      setCurrentSelectionIndex(nextIndex);
      setSliderValue(channel.selections[nextIndex]?.user_rating === null ? 0 : channel.selections[nextIndex]?.user_rating);
      onSelectionChange(nextIndex);
    }
  }, [currentSelectionIndex, channel.selections, onSelectionChange]);

  const previousProposition = useCallback(() => {
    if (currentSelectionIndex > 0) {
      const prevIndex = currentSelectionIndex - 1;
      setCurrentSelectionIndex(prevIndex);
      setSliderValue(channel.selections[prevIndex]?.user_rating === null ? 0 : channel.selections[prevIndex]?.user_rating);
      onSelectionChange(prevIndex);
    }
  }, [currentSelectionIndex, channel.selections, onSelectionChange]);

  const interpolateColor = (color1, color2, factor) => {
    const r1 = parseInt(color1.slice(1, 3), 16);
    const g1 = parseInt(color1.slice(3, 5), 16);
    const b1 = parseInt(color1.slice(5, 7), 16);

    const r2 = parseInt(color2.slice(1, 3), 16);
    const g2 = parseInt(color2.slice(3, 5), 16);
    const b2 = parseInt(color2.slice(5, 7), 16);

    const r = Math.round(r1 + factor * (r2 - r1));
    const g = Math.round(g1 + factor * (g2 - g1));
    const b = Math.round(b1 + factor * (b2 - b1));

    return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
  };

  const remapValue = (value, fromLow, fromHigh, toLow, toHigh) => {
    return ((value - fromLow) * (toHigh - toLow)) / (fromHigh - fromLow) + toLow;
  };
  

  const getGradientColors = useCallback((value) => {
    const { channel_gradient_start, channel_gradient_end } = channel;
    let topColor, bottomColor;

    if (!isAdvancedMode) return [channel_gradient_start, channel_gradient_end];
  
    // Remap only the lower portion (e.g., 0-20) to start higher on the spectrum
    let adjustedValue = value;
    if (value <= 20) {
      adjustedValue = remapValue(value, 0, 20, 10, 20); // Remap 0-20 to 10-20
    }
  
    if (adjustedValue <= 50) {
      const factor = 1 - (adjustedValue / 50);
      topColor = interpolateColor(channel_gradient_start, '#FFFFFF', factor);
      bottomColor = channel_gradient_end;
    } else {
      const factor = (adjustedValue - 50) / 50;
      topColor = channel_gradient_start;
      bottomColor = interpolateColor(channel_gradient_end, channel_gradient_start, factor);
    }
  
    return [topColor, bottomColor];
  }, [channel]);

  const gradientColors = useMemo(() => getGradientColors(sliderValue), [getGradientColors, sliderValue]);

  useEffect(() => {
    setGradientColors(gradientColors);
  }, [gradientColors]);

  // Add this new state

  // Add this new function
  const handleShare = useCallback(() => {
    const selectionLanguage = selection.meme.type === 'text' ? selection.meme.text : null;
    const shareUrl = `https://supermega.world/?uid=${user._id}&cid=${channel.channel_id}&sid=${selection.selection_id}`;

    const finalMessage = `${selectionLanguage}\n\nRate it: ${shareUrl}`;
    navigator.clipboard.writeText(finalMessage).then(() => {
      setShareSuccess(true);
      setTimeout(() => setShareSuccess(false), 2000);
    });
  }, [channel.channel_id, selection.selection_id]);

  return (
    <div className="channel-player">
      <BadgeSummary visible={badgeModalIsOpen} onClose={() => setBadgeModalIsOpen(!badgeModalIsOpen)} onChannelChange={onChannelChange} />
      <div className="badges-container" onClick={() => setBadgeModalIsOpen(!badgeModalIsOpen)}>
        {renderBadges(badgeDisplays)}
      </div>
      {selection && (
        <div className="proposition-container">
          <h3 className="meme-text">{selection.meme.text}</h3>
          {/* <div className="mode-toggle">
            <button onClick={() => setIsAdvancedMode(!isAdvancedMode)}>
              {isAdvancedMode ? "Basic" : "Advanced"}
            </button>
          </div> */}
          {isAdvancedMode ? (
            <div className="slider-container">
              <div className="slider-value">{channel.selections[currentSelectionIndex]?.user_rating === null && Math.round(sliderValue) === 0 ? '?' : Math.round(sliderValue)}</div>
              <ReactSlider
                className="horizontal-slider"
                thumbClassName="example-thumb"
                trackClassName="example-track"
                defaultValue={0}
                value={sliderValue}
                onChange={handleSliderChange}
                onAfterChange={handleSliderComplete}
              />
              <div className="spectrum-values">
                <span className="spectrum-value">{selection.spectrum.value1.toUpperCase()}</span>
                <span className="spectrum-value">{selection.spectrum.value2.toUpperCase()}</span>
              </div>
            </div>
          ) : (
            <div className="basic-rating-container">
              <div class="star-rating">
                {renderStarRating()}
              </div>
              <div className="spectrum-values">
                <span className="spectrum-value">{selection.spectrum.value1.toUpperCase()}</span>
                <span className="spectrum-value">{selection.spectrum.value2.toUpperCase()}</span>
              </div>
            </div>
          )}
          <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
            <button onClick={handleShare} className="share-button">
              {shareSuccess ? 'Copied!' : 'Share'}
            </button>
          </div>
          <div className="proposition-controls">
            <button disabled={currentSelectionIndex === 0} onClick={() => previousProposition()}>←</button>
            <span>{currentSelectionIndex + 1} / {channel.selections.length}</span>
            <button disabled={currentSelectionIndex === channel.selections.length - 1} onClick={() => nextProposition()}>→</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChannelPlayer;
