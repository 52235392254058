import React from 'react';
import './Contact.css';

import { app_name, support_email } from '../utils/constants';

export default function Contact() {
  return (
    <div className='contact'>
      <h1>Contact</h1>
      <p>{`To reach the ${app_name} team please email ${support_email} and a team member will be with you as soon as possible.`}</p>
    </div>
  );
}