import React, { useState, useEffect } from 'react';
import './Profile.css';
import { useGlobalState } from '../context/GlobalState';
import { superSocket } from '../utils/api';
import LoadingSpinner from './LoadingSpinner';
import 'react-confirm-alert/src/react-confirm-alert.css';

const Profile = ({ }) => {
  const { user, setUser, signInUser, handleChannelsList, gradientColors, userSignOut, saveProfileIsOpen, setSaveProfileIsOpen } = useGlobalState();
  const [isLoading, setIsLoading] = useState(false);
  const [tempUsername, setTempUsername] = useState('');
  const [tempEmail, setTempEmail] = useState('');
  const [tempPassword, setTempPassword] = useState('');
  const [isSignInMode, setIsSignInMode] = useState(false); // New state for toggling view mode

  useEffect(() => {
    const handleUpdateResponse = async userData => {
      const userClone = { ...user };
      for (const key in userData) {
        userClone[key] = userData[key];
      }
      setUser(userClone);
      setIsLoading(false);
      alert('Save Successful!');
      setSaveProfileIsOpen(false);
    };

    const handleSignIn = async userPayload => {
      userSignOut();
      localStorage.setItem('token', userPayload.token);
      localStorage.setItem('user', userPayload._id.toString());
      signInUser(userPayload);
      getChannels(userPayload);
      setIsSignInMode(false);
      setIsLoading(false);
      setSaveProfileIsOpen(false);
    };

    const handleChannels = async channels => {
      handleChannelsList(channels);
    };

    const handleFailure = (err) => {
      alert(err.message);
      setIsLoading(false);
    }

    const handleError = (err) => {
      setIsLoading(false);
    }

    superSocket.on('deliverUpdateProfileRes', handleUpdateResponse);
    superSocket.on('deliverUserSignIn', handleSignIn);
    superSocket.on('updateUserFailure', handleFailure);
    superSocket.on('error-event', handleError);
    superSocket.on('deliverChannelsList', handleChannels);


    return () => {
      superSocket.off('deliverUpdateProfileRes', handleUpdateResponse);
      superSocket.off('updateUserFailure', handleFailure);
      superSocket.off('deliverUserSignIn', handleSignIn);
      superSocket.off('deliverChannelsList', handleChannels);
      superSocket.off('error-event', handleError);
    };
  }, []);

  useEffect(() => {
    if (user) {
      setTempUsername(user.username || '');
      setTempEmail(user.email || '');
    } else {
      setTempUsername('');
      setTempEmail('');
    }
    setTempPassword('');
  }, [user]);

  const saveUpdate = async () => {
    setIsLoading(true);
    const { username, email } = user;

    const original = { username, email };
    const updated = { username: tempUsername, email: tempEmail };
    const payload = { password: tempPassword };

    for (const key in updated) {
      if (original.hasOwnProperty(key) && original[key] !== updated[key]) {
        payload[key] = updated[key];
      }
    }

    if (Object.keys(payload).length === 1 && payload.password === '') {
      alert('No changes to save!');
      setIsLoading(false);
      return;
    }

    const token = await localStorage.getItem('token');
    const userId = await localStorage.getItem('user');

    const auth = { token, userId };
    payload._id = user._id;
    if (tempPassword.length > 0) {
      payload.password = tempPassword;
    } else {
      delete payload.password;
    }

    superSocket.emit('updateUserProfile', payload, auth);
  }

  const signIn = async () => {
    if (tempUsername.toLowerCase() === user?.username?.toLowerCase()) {
      alert('User already signed in!');
      return;
    }

    setIsLoading(true);
    const payload = { username: tempUsername, password: tempPassword };

    if (!payload.username || !payload.password) {
      alert('Username and password are required!');
      setIsLoading(false);
      return;
    }

    superSocket.emit('userSignIn', payload);
  }

  const signOut = () => {
    if (!user.username || !user.email) {
      alert('no user to sign out');
      return;
    }
    setIsLoading(true);
    userSignOut();
    window.location.reload();
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isSignInMode) {
      signIn();
    } else {
      saveUpdate();
    }
  };

  const getChannels = async (user) => {
    const payload = {
      user: user._id,
      channel_id: null,
      selection_id: null,
    };

    superSocket.emit('getChannels', payload);
  }


  if (!saveProfileIsOpen) return null;

  document.documentElement.style.setProperty('--gradient-color-1', gradientColors[0]);
  document.documentElement.style.setProperty('--gradient-color-2', gradientColors[1]);

  return (
    <div className="modal">
      <div className="modal-content">
        <div className="modal-header">
          <h2>{isSignInMode ? 'Sign In' : `${user.username || user.email ? `Update Profile` : `Sign Up`}`}</h2>
          <button onClick={() => setSaveProfileIsOpen(false)}>&times;</button>
        </div>
        <div className="modal-body">
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <form onSubmit={handleSubmit} className="form-container">
              <div className="form-group">
                <label htmlFor="username">Username</label>
                <input
                  id="username"
                  type="text"
                  value={tempUsername}
                  onChange={(e) => setTempUsername(e.target.value)}
                />
              </div>
              {!isSignInMode && (
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    id="email"
                    type="email"
                    value={tempEmail}
                    onChange={(e) => setTempEmail(e.target.value)}
                  />
                </div>
              )}
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input
                  id="password"
                  type="password"
                  value={tempPassword}
                  onChange={(e) => setTempPassword(e.target.value)}
                />
              </div>
              <div className="button-group">
                {isSignInMode ? (
                  <button type="submit">Sign In</button>
                ) : (
                  <>
                    <button type="submit">{user.username || user.email ? 'Save' : 'Sign Up'}</button>
                    {
                      user.username || user.email ? (      
                        <button type="button" onClick={signOut}>Sign Out</button>
                      ) : null
                    }
                  </>
                )}
              </div>
              <div className="toggle-mode-button">
                <p onClick={() => setIsSignInMode(!isSignInMode)}>
                  {isSignInMode ? `${user.username || user.email ? `Update Profile` : 'Sign Up'}` : `${user.username || user.email ? `Sign In to Different Account` : `Sign In`}`}
                </p>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default Profile;
