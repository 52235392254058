import React, { useState, useEffect } from 'react';
import { renderEmoji } from '../utils/emojiUtils';
import ActiveToggle from '../ActiveToggle';
import '../CmsLanding.css';
import emoji from 'emoji-dictionary';

const EmojiPicker = ({ onSelect }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const emojiList = emoji.unicode.map((char, index) => ({
    name: emoji.names[index],
    char: char
  }));

  const filteredEmojis = emojiList.filter(item =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="emoji-picker-wrapper" onClick={(e) => e.stopPropagation()}>
      <input
        type="text"
        placeholder="Search emojis..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="emoji-search"
      />
      <div className="emoji-picker">
        {filteredEmojis.length > 0 ? (
          filteredEmojis.map((item) => (
            <span
              key={item.name}
              onClick={() => onSelect({ name: item.name, char: item.char })}
              className="emoji-option"
              title={item.name}
            >
              {item.char}
            </span>
          ))
        ) : (
          <div className="no-results">No emojis found</div>
        )}
      </div>
    </div>
  );
};

const ItemModal = ({ isOpen, onClose, onSubmit, activeList, editingItem, isLoading, resetForm }) => {
  const [formData, setFormData] = useState({});
  const [showEmojiPicker, setShowEmojiPicker] = useState(null);

  const setDefaultValues = (list, payload) => {
    switch (list) {
      case 'channels':
        return { active: false, type: 'curated', color: '#000000', gradient_start: '#EFEDED', gradient_end: '#EFEDED', ...payload };
      case 'memes':
        return { active: true, ...payload };
      case 'selections':
        return { ...payload };
      case 'badges':
        return { active: true, dichotomy_id: '1', dichotomy_value1_spectrum_value1: true, ...payload };
      case 'dichotomies':
        return { color: '#CCCCCC', ...payload };
      case 'spectrums':
        return { ...payload };
      default:
        return payload;
    }
  };

  useEffect(() => {
    if (editingItem) {
      const formattedItem = { ...editingItem };
      if (activeList === 'dichotomies') {
        formattedItem.emoji1 = formattedItem.emoji1?.replace(/:/g, '');
        formattedItem.emoji2 = formattedItem.emoji2?.replace(/:/g, '');
      }
      setFormData(formattedItem);
    }
  }, [editingItem, activeList]);

  useEffect(() => {
    if (!isOpen) {
      setShowEmojiPicker(null);
    }
  }, [isOpen]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (showEmojiPicker && !e.target.closest('.emoji-container')) {
        setShowEmojiPicker(null);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showEmojiPicker]);

  useEffect(() => {
    if (resetForm) {
      setFormData({});
    }
  }, [resetForm]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const submissionData = { ...formData };
    if (activeList === 'dichotomies') {
      submissionData.emoji1 = `:${formData.emoji1}:`;
      submissionData.emoji2 = `:${formData.emoji2}:`;
    }
    // if (['channels', 'memes', 'selections', 'badges'].includes(activeList)) {
    //   submissionData.active = formData.active !== undefined ? formData.active : true;
    // }
    // modify the submissionData to include the default values for the activeList
    const addedDefaultValues = setDefaultValues(activeList, submissionData);
    console.log(addedDefaultValues);
    onSubmit(addedDefaultValues, editingItem ? 'update' : 'create');
    if (!editingItem) {
      setFormData({});
    }
  };

  if (!isOpen) return null;

  const renderForm = () => {
    const formFields = [];

    if (['channels', 'memes', 'selections'].includes(activeList)) {
      const defaultActive = activeList !== 'channels';
      formFields.push(
        <div key="active" className="form-row">
          <label>Active</label>
          <ActiveToggle
            isActive={formData.active === undefined ? defaultActive : formData.active}
            onChange={(value) => setFormData({ ...formData, active: value })}
          />
        </div>
      );
    }

    const renderColorInput = (name, label, defaultColor = '#CCCCCC') => {
      return (
        <div key={name} className="form-row">
          <label>{label}</label>
          <div className="color-input">
            <input
              type="text"
              name={name}
              value={formData[name] || defaultColor}
              onChange={handleChange}
              placeholder="Enter hex code"
            />
            <input
              type="color"
              value={formData[name] || defaultColor}
              onChange={(e) => handleChange({ target: { name, value: e.target.value } })}
            />
          </div>
        </div>
      );
    };

    const renderEmojiInput = (emojiField, label) => {
      const handleEmojiSelect = (selectedEmoji) => {
        setFormData(prevData => ({
          ...prevData,
          [emojiField]: selectedEmoji.name
        }));
        setShowEmojiPicker(null);
      };

      return (
        <div key={emojiField} className="form-row">
          <label>{label}</label>
          <div className="emoji-container">
            <div
              className="emoji-preview"
              onClick={() => setShowEmojiPicker(showEmojiPicker === emojiField ? null : emojiField)}
            >
              {formData[emojiField] ? renderEmoji(`:${formData[emojiField]}:`) : '➕'}
            </div>
            <input
              name={emojiField}
              value={formData[emojiField] || ''}
              onChange={(e) => handleEmojiChange(e, emojiField)}
              placeholder={`Enter ${label}`}
            />
            {showEmojiPicker === emojiField && (
              <EmojiPicker onSelect={handleEmojiSelect} onClose={() => setShowEmojiPicker(null)} />
            )}
          </div>
        </div>
      );
    };

    const handleEmojiChange = (e, emojiField) => {
      const strippedEmoji = validateEmoji(e.target.value);
      setFormData(prevData => ({
        ...prevData,
        [emojiField]: strippedEmoji
      }));
    };

    const validateEmoji = (value) => {
      if (!value) return value;
      // Remove all colons from the input
      return value.replace(/:/g, '');
    };

    const renderInput = (name, label, type = 'text', placeholder = '') => {
      return (
        <div key={name} className="form-field">
          <label htmlFor={name}>{label}</label>
          <input
            id={name}
            name={name}
            type={type}
            value={formData[name] || ''}
            onChange={handleChange}
            placeholder={placeholder}
            title={label}
          />
        </div>
      );
    };

    const renderTextarea = (name, label, placeholder = '') => {
      return (
        <div key={name} className="form-field">
          <label htmlFor={name}>{label}</label>
          <textarea
            id={name}
            name={name}
            value={formData[name] || ''}
            onChange={handleChange}
            placeholder={placeholder}
            title={label}
          />
        </div>
      );
    };

    const renderSelect = (name, label, options) => {
      return (
        <div key={name} className="form-field">
          <label htmlFor={name}>{label}</label>
          <select
            id={name}
            name={name}
            value={formData[name] || ''}
            onChange={handleChange}
            title={label}
          >
            {options.map(option => (
              <option key={option.value} value={option.value}>{option.label}</option>
            ))}
          </select>
        </div>
      );
    };

    switch (activeList) {
      case 'channels':
        formFields.push(
          renderInput('title', 'Title'),
          renderSelect('type', 'Type', [
            { value: 'curated', label: 'Curated' }
          ]),
          renderTextarea('description', 'Description'),
          renderColorInput('color', 'Color', formData.color || '#000000'),
          renderColorInput('gradient_start', 'Gradient Start', formData.gradient_start || '#EFEDED'),
          renderColorInput('gradient_end', 'Gradient End', formData.gradient_end || '#EFEDED')
        );
        break;
      case 'memes':
        formFields.push(
          renderSelect('type', 'Type', [
            { value: '', label: 'Select type' },
            { value: 'text', label: 'Text' },
            { value: 'image', label: 'Image' }
          ]),
          renderTextarea('text', 'Text'),
          renderInput('url', 'URL')
        );
        break;
      case 'spectrums':
        formFields.push(
          renderInput('value1', 'Value 1'),
          renderInput('value2', 'Value 2')
        );
        break;
      case 'badges':
        formFields.push(
          renderInput('dichotomy_id', 'Dichotomy ID'),
          renderInput('selection_id', 'Selection ID'),
          <div key="dichotomy_value1_spectrum_value1" className="form-row">
            <label>Dichotomy Value 1 Spectrum Value 1</label>
            <ActiveToggle
              isActive={formData.dichotomy_value1_spectrum_value1 || false}
              onChange={(value) => setFormData({ ...formData, dichotomy_value1_spectrum_value1: value })}
            />
          </div>
        );
        break;
      case 'dichotomies':
        formFields.push(
          renderColorInput('color', 'Color'),
          renderInput('value1', 'Value 1'),
          renderInput('label1', 'Label 1'),
          renderEmojiInput('emoji1', 'Emoji 1'),
          renderTextarea('definition1', 'Definition 1'),
          renderInput('value2', 'Value 2'),
          renderInput('label2', 'Label 2'),
          renderEmojiInput('emoji2', 'Emoji 2'),
          renderTextarea('definition2', 'Definition 2')
        );
        break;
      case 'selections':
        formFields.push(
          renderInput('channel_id', 'Channel ID'),
          renderInput('meme_id', 'Meme ID'),
          renderInput('spectrum_id', 'Spectrum ID')
        );
        break;
      default:
        return null;
    }

    return formFields;
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <div className="modal-header">
          <h2>{editingItem ? 'Edit' : 'Create'} {activeList.charAt(0).toUpperCase() + activeList.slice(1, -1)}</h2>
          <div className="modal-actions">
            <button className="cms-button cancel" onClick={onClose} disabled={isLoading}>Cancel</button>
            <button className="cms-button primary" onClick={handleSubmit} disabled={isLoading}>
              {isLoading ? 'Loading...' : (editingItem ? 'Update' : 'Save')}
            </button>
          </div>
        </div>
        <form onSubmit={handleSubmit} className="modal-form">
          {renderForm()}
        </form>
      </div>
    </div>
  );
};

export default ItemModal;
