import React from 'react';
import '../CmsLanding.css';

// type: Joi.string().max(10).required(),
// text: Joi.string().max(500),
// url: Joi.string().max(200),
// creator: Joi.number().required(),
// active: Joi.boolean().default(true)

const MemeList = ({ memes, onItemClick, createButton }) => {
  return (
    <>
      {createButton}
      <table className="cms-table memes-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Active</th>
            <th>Text</th>
            <th>Type</th>
            <th>Url</th>
            <th>Creator</th>
            <th>Created Date</th>
          </tr>
        </thead>
        <tbody>
          {memes.map(meme => (
            <tr key={meme._id} onClick={() => onItemClick(meme)}>
              <td>{meme._id}</td>
              <td>{meme.active ? 'True' : 'False'}</td>
              <td>{meme.text}</td>
              <td>{meme.type}</td>
              <td>{meme.url}</td>
              <td>{meme.creator}</td>
              <td>{new Date(meme.created_date).toLocaleDateString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default MemeList;
